
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  name: "Layout",
  components: {
    Aside: defineAsyncComponent(() => import("./includes/aside/Aside.vue")),
    Header: defineAsyncComponent(() => import("./includes/header/Header.vue")),
    Main: defineAsyncComponent(() => import("./includes/main/Main.vue")),
    ModelDialog: defineAsyncComponent(
      () => import("./includes/ModelDialog.vue")
    ),
    ModelDrawer: defineAsyncComponent(
      () => import("./includes/ModelDrawer.vue")
    )
  }
});
