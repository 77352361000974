import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Aside = _resolveComponent("Aside")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Main = _resolveComponent("Main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_ModelDialog = _resolveComponent("ModelDialog")!
  const _component_ModelDrawer = _resolveComponent("ModelDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_container, { class: "main-layout" }, {
      default: _withCtx(() => [
        _createVNode(_component_Aside, { class: "layout-aside" }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Header),
          _createVNode(_component_Main)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ModelDialog),
    _createVNode(_component_ModelDrawer)
  ], 64))
}